import { graphql, Link, useStaticQuery } from "gatsby"
import { node } from "prop-types"
import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ThoughtsIndexContainer = styled.div`
  width: 66%;
  min-height: 75vh;
  margin: 4rem auto auto auto;
  h1 {
    padding-top: 4rem;
    text-align: center;
  }
  ul {
    width: 100%;
    margin: auto;
    list-style: none;
    li::before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      top: 11px;
      left: -20px;
      border-radius: 50%;
      background-color: #008080;
    }
    li {
      position: relative;
      font-size: 1.1rem;
      .header-link {
        color: inherit !important;
      }
      a:link {
        text-decoration: none;
        color: #008080;
        cursor: pointer;
      }
      a:visited {
        text-decoration: none;
        color: #008080;
        cursor: pointer;
      }
      &:hover {
        a {
          text-decoration: underline;
        }
      }
      h2 {
        font-size: 1.2rem;
        margin: 0;
      }
      .time {
        font-style: italic;
        margin: 0.4rem 0;
        span {
          font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
            monospace;
          background: rgba(230, 235, 241, 0.5);
        }
      }
      p {
        font-size: 1rem;
        margin: 0;
      }
    }
  }
  @media (max-width: 800px) {
    width: 90%;
    h1 {
      font-size: 1.5rem;
    }
    ul {
      width: 80%;
    }
  }
`

const ThoughtsIndexPage = () => {
  // Query the pages (filtering out index and 404)
  const { pages, markdown } = useStaticQuery(graphql`
    query Pages {
      pages: allFile(
        filter: {
          sourceInstanceName: { eq: "pages" }
          relativeDirectory: { eq: "thoughts" }
          name: { regex: "/^(?!index|404).*$/" }
        }
      ) {
        nodes {
          name
          relativePath
          id
          relativeDirectory
        }
      }
      markdown: allMdx(sort: { order: DESC, fields: frontmatter___date }) {
        edges {
          node {
            frontmatter {
              title
              date
              slug
            }
            excerpt(truncate: true)
            timeToRead
            id
          }
        }
      }
    }
  `)

  const allPages = pages.nodes.map((page: any, i: number) => {
    const link = page.relativePath.split(`${page.relativeDirectory}/`)[1]
    let name = page.name.replace(/-/g, " ")
    return (
      <li key={`${name + i}`}>
        {name} <Link to={`${link.split(".tsx")[0]}/`}>Read</Link>
      </li>
    )
  })

  // @ts-ignore
  markdown.edges.map(node => {
    const { slug, title, date } = node.node.frontmatter
    const id = node.node.id
    const excerpt = node.node.excerpt
    const timteToRead = node.node.timeToRead
    allPages.push(
      <li key={id}>
        <Link to={slug} className="header-link">
          <h2>{title}</h2>
        </Link>
        <p className="time">
          <span>{date}</span> - {timteToRead} min read
        </p>
        <p>{excerpt}</p>
        <Link to={slug}>Read</Link>
      </li>
    )
  })

  return (
    <Layout>
      <SEO title="Our Thoughts and Blog Posts | Get Fitness Fluent" />
      <ThoughtsIndexContainer>
        <h1>A Collection Of Our Thoughts</h1>
        <ul>{allPages}</ul>
      </ThoughtsIndexContainer>
    </Layout>
  )
}

export default ThoughtsIndexPage
